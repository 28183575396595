import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import styles from './index.module.scss'
import Layout from 'components/layout'
import SEO from 'components/seo'
import IconBtn from 'components/icon-btn-link'
import intl from '@/intl'
import toTitleCase from 'titlecase'
import Img from 'gatsby-image'
import { getSources } from '@/utils'
import MobileBtn from 'components/mobile-btn'
import LoopScroll from 'components/loop-scroll'

const ContactPage = (props) => {
  const parent = React.createRef()
  const image = props.data.allContentfulMisc.nodes[0].contactPageImage
  const { email, telephone, pressEmail, googleMapsUrl, address, info, instagramUrl, instagramText } = props.data.allContentfulMisc.nodes[0]
  const pad = `p-4`
  const contactInfo = (
    <>
      <div><IconBtn icon={`→`} to={`mailto:${email.toLowerCase()}`} className={``}>{email}</IconBtn></div>
      <p className={`mb-2`}><a href={`tel:${telephone}`}>{telephone}</a></p>
      <div className='mb-4 mt-4'><IconBtn icon={`→`} to={`mailto:${pressEmail.toLowerCase()}`} className={``}>{pressEmail}</IconBtn></div>
      <p className={``}><a target={`_blank`} href={`${googleMapsUrl}`}>{address}</a></p>
      <p className={`mb-4`}>{info.info}</p>
      <p><a target={`_blank`} href={`${instagramUrl}`}>{instagramText}</a></p>
    </>
  )
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    const el = parent.current
    const hero = [...el.getElementsByClassName('hero-image')]
    if (hero) {
      hero.forEach(item => {
        item.style.width = `${window.innerWidth}px`
        item.style.height = `${window.innerHeight * 0.9}px`
      })
    }
  })
  return (
    <Layout>
      <SEO
        title={toTitleCase(intl(`contact`))}
        lang={props.pageContext.code}
        pathname={props.location.pathname}
      />
      <div className={`${styles.parent} text-base`} ref={parent}>
        <div className={`hidden lg:block`}>
          <div className={`w-4/7 h-screen fixed top-0 left-0`}>
            <Img
              imgStyle={{objectFit: 'cover'}}
              className={styles.img}
              fluid={getSources(image)}
            />
          </div>
          <section className={`w-3/7 bg-white fixed h-screen right-0`}>
            <h1 className={`text-left ${pad} p-4 pl-logo pt-4 uppercase`}>{toTitleCase(intl(`contact`))}</h1>
            <div className={`bottom-0 absolute ${pad} p-4 pl-logo pb-4 uppercase`}>{ contactInfo }</div>
          </section>
        </div>
        <div className={`block lg:hidden`}>
          <LoopScroll disableInDesktop fixedTargetIndex={1}>
            <h1 className={`fixed z-20 uppercase p-4 pl-logo`}>{intl('contact')}</h1>
            <div className={`w-full h-screen overflow-hidden hero-image`}>
              <Img className={styles.img} imgStyle={{objectFit: `cover`}} fluid={getSources(image)} />
            </div>
            <section className={`w-full uppercase pt-5 p-4 pl-logo`}>
              { contactInfo }
              <MobileBtn className={`mb-1 mt-4`} to={`mailto:${email}`}>{intl('email_us')}</MobileBtn>
            </section>
          </LoopScroll>
        </div>
      </div>
    </Layout>
  )
}

export const AboutQuery = graphql`
  query ContactQuery($code: String) {
    allContentfulMisc(filter: {node_locale: {eq: $code}}) {
      nodes {
        siteTitle
        siteDescription
        email
        telephone
        pressEmail
        address
        instagramText
        instagramUrl
        googleMapsUrl
        info {
          info
        }
        contactPageImage {
          desktop {
            fluid(maxWidth: 2200, quality: 70) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mobile {
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default ContactPage
